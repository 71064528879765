@import './breakpoint.var.scss'; 

@mixin media-breakpoint-landing($breakpointName) {
  $mediaQuery: map-get($breakpoints, $breakpointName);

  @if ($mediaQuery == null) {
    @content;
  } @else {
    @media #{$mediaQuery} {
      @content;
    }
  }
}

 