
@mixin font-primary($size, $weig, $line) {
    font-size: $size;
    font-weight: $weig;
    line-height: $line;
    font-family: "Red Hat Display";
}

@mixin font-secondary($size, $weig, $line) {
    font-size: $size;
    font-weight: $weig;
    line-height: $line;
    font-family: "Nunito";
}

/* Red Hat */
.font-primary-bold{
    @include font-primary(16px, bold, normal);
}
.font-primary-bold-h1{
    @include font-primary(34px, 700,34px);
}
.font-primary-bold-h2{
    @include font-primary(26px, 700,30px);
}
.font-primary-bold-h3{
    @include font-primary(22px, 700,26px);
}
.font-primary-bold-h4{
    @include font-primary(20px, 700,24px);
}
.font-primary-bold-h5{
    @include font-primary(18px, 700,22px);
}
.font-primary-bold-h6{
    @include font-primary(16px, 700,20px);
}
/*medium*/
.font-primary-medium{
    @include font-primary(16px, 500, normal);
}
/*regular*/
.font-primary-regular{
    @include font-primary(16px, 400, normal);
}

/* Red Hat-mobile*/
.font-primary-mobile-bold-h1{
    @include font-primary(24px, 700,28px);
}
.font-primary-mobile-bold-h2{
    @include font-primary(22px, 700,26px);
}
.font-primary-mobile-bold-h3{
    @include font-primary(20px, 700,24px);
}
.font-primary-mobile-bold-h4{
    @include font-primary(18px, 700,22px);
}
.font-primary-mobile-bold-h5{
    @include font-primary(16px, 700,20px);
}
.font-primary-mobile-bold-h6{
    @include font-primary(14px, 700,18px);
}


/*medium*/
.font-primary-medium{
    @include font-primary(16px, 500, normal);
}
/*regular*/
.font-primary-regular{
    @include font-primary(16px, 400, normal);
}






/** Nunito */
.font-secondary-bold{
    @include font-secondary(16px, 700, normal);
}
.font-secondary-bold-16{
  @include font-secondary(16px, 700, 22px);
}
.font-secondary-bold-20 {
  @include font-secondary(20px, 700, 24px);
}
.font-secondary-bold-24{
    @include font-secondary(24px, 700, 30px);
}
.font-secondary-12{
    @include font-secondary(12px, 400, 16px);
}
.font-secondary-14{
    @include font-secondary(14px, 400, 20px);
}
.font-secondary-bold-14{
    @include font-secondary(14px, 700, 20px);
}
.font-secondary-bold-12{
    @include font-secondary(12px, 700, 18px);
}

/*medium*/
.font-secondary-medium-26{
    @include font-secondary(26px, 500,32px);
}
.font-secondary-medium-24{
    @include font-secondary(24px, 500,32px);
}
/*Regular*/
.font-secondary-regular-16{
    @include font-secondary(16px, 400, 22px);
}
.font-secondary-regular-14{
    @include font-secondary(14px, 400, 20px);
}
.font-secondary-regular-12{
    @include font-secondary(12px, 400,18px);
}
.font-secondary-regular-10{
    @include font-secondary(10px, 400,14px);
}
