.bg-color-primary{
    background-color: #640AB0;
}
.bg-color-primary-600{
    background-color: #B289D6;
}
.bg-color-primary-500{
    background-color: #C7B1DA;
}
.bg-color-primary-400{
    background-color: #CCC1D5;
}
.bg-color-primary-300{
    background-color: #ECE2F6;
}
.bg-color-primary-200{
    background-color: #F1EEF4;
}
.bg-color-primary-100{
    background-color:  #FBF8FD;
}


.bg-color-secondary{
    background-color: #FF4D5E;
}
.bg-color-secondary-300{
    background-color: #E45765;
}
.bg-color-secondary-200{
    background-color: #FF7784;
}
.bg-color-secondary-100{
    background-color: #FEC3C8;
}



.bg-color-lightgreen{
    background-color: #68ECD5;
}
.bg-color-green{
    background-color: #0B662A;
}



.bg-color-blue{
    background-color: #053296;
}
.bg-color--lightblue{
    background-color: #275BCE;
}

.bg-color-yellow{
    background-color: #FFE814;
}


.bg-color-black{
    background-color: #212121;
}
.bg-color-black-300{
    background-color: #BFBDBD;
}
.bg-color-black-200{
    background-color: #999797;
}
.bg-color-black-300{
    background-color: #626262;
}
.bg-color-white{
    background-color: #FFFFFF;
}




.bg-color-gradient{
  background: linear-gradient(90.51deg, #640AB0 0.39%, #053296 138.52%);
}



/*colores para los textos*/

.color-primary{
    color:  #640AB0;
}
.color-primary-600{
    color: #B289D6;
}
.color-primary-500{
    color: #C7B1DA;
}
.color-primary-400{
    color: #CCC1D5;
}
.color-primary-300{
    color: #ECE2F6;
}
.color-primary-200{
    color: #F1EEF4;
}
.color-primary-100{
    color: #FBF8FD;
}



.color-secondary{
    color: #FF4D5E;
}
.color-secondary-300{
    color: #E45765;
}
.color-secondary-200{
    color: #FF7784;
}
.color-secondary-100{
    color: #FEC3C8;
}



.color-lightgreen{
    color: #68ECD5;
}
.color-green{
    color: #0B662A;
}



.color-blue{
    color: #053296;
}
.color--lightblue{
    color: #275BCE;
}

.color-yellow{
    color: #FFE814;
}


.color-black{
    color: #212121;
}
.color-black-200{
    color: #999797;
}
.color-black-300{
    color: #626262;
}
.color-black-400{
    color: #646464;
}
.color-black-100{
    color: #333333;
}


.color-white{
    color: #FFFFFF;
}
