 [tooltip] {
    position: relative; 
  
    &::before, &::after {
      text-transform: none; 
      font-size: .9em; 
      line-height: 1;
      user-select: none;
      pointer-events: none;
      position: absolute;
      display: none;
      opacity: 0;
    }
  
    &::before {
      content: '';
      border: 7px solid transparent;
      z-index: 1001;
    }
  
    &::after {
      content: attr(tooltip);
      font-family: Helvetica, sans-serif;
      text-align: center; 
      min-width: 200px;
      max-width: 21em;
      white-space: pre-wrap; 
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 1ch 1.5ch;
      border-radius: .6ch; 
      background: #F1EEF4; 
      color: #212121;
      z-index: 1000; 
    }
  
    &:hover {
      &::before, &::after {
        display: block;
      }
    }
  } 
  
  [tooltip=''] {
    &::before, &::after {
      display: none !important;
    }
  }
   
  [tooltip] {
    &:not([position])::before, &[position^="up"]::before {
      bottom: 105%;
      border-bottom-width: 0;
      border-top-color: #F1EEF4;
    }
  
    &:not([position])::after, &[position^="up"]::after {
      bottom: calc(100% + 8px);
    }
  
    &:not([position]) {
      &::before, &::after {
        left: 50%;
        transform: translate(-50%, -0.5em);
      }
    }
  
    &[position^="up"] {
      &::before, &::after {
        left: 50%;
        transform: translate(-50%, -0.5em);
      }
    }

      
    &[position^="up-left"] {
      &::before {
        bottom: 105%;
        left: 5%;
        border-bottom-width: 0;
        border-top-color: #F1EEF4;
      }
  
      &::after {
        bottom: calc(100% + 8px);
      }
    }

    &[position^="up-right"] {
      &::before {
        bottom: 105%;
        left: 90%;
        border-bottom-width: 0;
        border-top-color: #F1EEF4;
      }
  
      &::after {
        bottom: calc(100% + 8px);
      }
    }


   
    &[position^="down"] {
      &::before {
        top: 100%;
        border-top-width: 0;
        border-bottom-color: #F1EEF4;
      }
  
      &::after {
        top: calc(100% + 5px);
      }
  
      &::before, &::after {
        left: 50%;
        transform: translate(-50%, 0.5em);
      }
    }
  
    &[position^="left"] {
      &::before {
        top: 50%;
        border-right-width: 0;
        border-left-color: #F1EEF4;
        left: calc(0em - 8px);
        transform: translate(-0.5em, -50%);
      }
  
      &::after {
        top: 50%;
        right: calc(100% + 8px);
        transform: translate(-0.5em, -50%);
      }
    }
 
    &[position^="right"] {
      &::before {
        top: 50%;
        border-left-width: 0;
        border-right-color: #F1EEF4;
        right: calc(0em - 8px);
        transform: translate(0.5em, -50%);
      }
  
      &::after {
        top: 50%;
        left: calc(100% + 8px);
        transform: translate(0.5em, -50%);
      }
    }
    
    &[position^="right-up"] {
      &::before {
        top: 30%;
        border-left-width: 0;
        border-right-color: #F1EEF4;
        right: calc(0em - 8px);
        transform: translate(0.5em, -50%);
      }
  
      &::after {
        top: 60%;
        left: calc(100% + 8px);
        transform: translate(0.5em, -50%);
      }
    }
    &[position^="right-down"] {
      &::before {
        top: 60%;
        border-left-width: 0;
        border-right-color: #F1EEF4;
        right: calc(0em - 8px);
        transform: translate(0.5em, -50%);
      }
  
      &::after {
        top: 30%;
        left: calc(100% + 8px);
        transform: translate(0.5em, -50%);
      }
    }


  
    &:not([position]):hover {
      &::before, &::after {
        animation: tooltips-vert 300ms ease-out forwards;
      }
    }
  
    &[position^="up"]:hover {
      &::before, &::after {
        animation: tooltips-vert 300ms ease-out forwards;
      }
    }
  
    &[position^="down"]:hover {
      &::before, &::after {
        animation: tooltips-vert 300ms ease-out forwards;
      }
    }
  
    &[position^="left"]:hover {
      &::before, &::after {
        animation: tooltips-horz 300ms ease-out forwards;
      }
    }
  
    &[position^="right"]:hover {
      &::before, &::after {
        animation: tooltips-horz 300ms ease-out forwards;
      }
    }
  }
   
  @keyframes tooltips-vert {
    to {
      opacity: .9;
      transform: translate(-50%, 0);
    }
  }
  
  @keyframes tooltips-horz {
    to {
      opacity: .9;
      transform: translate(0, -50%);
    }
  } 
  