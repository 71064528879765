@import 'breakpoints';

.cbx-container {
  position: relative;
  margin: 0 auto;
  width: 100%;

  @include media-breakpoint-landing("xs") {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  @include media-breakpoint-landing("sm") {
    max-width: 720px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  @include media-breakpoint-landing("md") {
    max-width: 910px;
  }
  
  @include media-breakpoint-landing("gt-md") {
    max-width: 1140px;
  } 
}

/* PARA DASHBOARD*/
.cbx-container-dashboard { 
  position: relative;
  margin: 0 auto;
  width: 100%;

  @include media-breakpoint-landing("xs") {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  @include media-breakpoint-landing("sm") {
    max-width: 850px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  @include media-breakpoint-landing("md") {
    max-width: 910px;
  }
  
  @include media-breakpoint-landing("gt-md") {
    max-width: 1140px;
  } 
}


.cbx-container-tool { 
  position: relative;
  margin: 0 auto;  
  padding-left: 24px;
  padding-right: 24px;

  @include media-breakpoint-landing("xs") {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include media-breakpoint-landing("sm") {
    width: 580px; 
    padding-left: 24px;
    padding-right: 24px;
  }
  
  @include media-breakpoint-landing("lg") { 
    padding-left: 30px;
    padding-right: 24px;
  }
  
 
}