.card-elevacion{
    background: #FFFFFF;
    box-shadow: 0px 0px 24px rgba(33, 33, 33, 0.1);
    border-radius: 20px;
}

.card-elevacion-16{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(33, 33, 33, 0.16);
    border-radius: 20px;
}

 