
.p-4{
    padding: 0.25rem;
}
.p-8{
    padding: 0.50rem;
}
.p-12{
    padding: 0.75rem;
}
.p-16{
    padding: 1rem;
}
.p-20{
    padding: 1.25rem;
}
.p-24{
    padding: 1.50rem;
}
.p-28{
    padding: 1.75rem;
}
.p-32{
    padding: 2rem;
}
.p-40{
    padding: 2.50rem;
}
.p-44{
    padding: 2.75rem;
}
.p-48{
    padding: 3rem;
}
.p-56{
    padding: 3.50rem;
}
.p-64{
    padding: 4rem;
}
.p-96{
    padding: 6rem;
}



.mb-0{
    display: block;
    margin-bottom: 0;
}
.mb-2{
    display: block;
    margin-bottom: 2%;
}
.mb-3{
    display: block;
    margin-bottom: 3%;
}
.mb-4{
    display: block;
    margin-bottom: 4%;
}
.mb-5{
    display: block;
    margin-bottom: 5%;
}
.mb-6{
    display: block;
    margin-bottom: 6%;
}
.mb-10{
    display: block;
    margin-bottom: 10%;
}



.mt-2{
    display: block;
    margin-top: 2%;
}
.mt-3{
    display: block;
    margin-top: 3%;
}
.mt-4{
    display: block;
    margin-top: 4%;
}
.mt-5{
    display: block;
    margin-top: 5%;
}
.mt-6{
    display: block;
    margin-top: 6%;
}



.mb-74{
    display: block;
    margin-bottom: 74px;
}


.mt-74{
    display: block;
    margin-top: 74px;
}


.mr-2{
    margin-left: 2%;
}
.ml-4{
    margin-left: 4%;
}
.ml-6{
    margin-left: 6%;
}


.mr-2{
    margin-right: 2%;
}
.mr-4{
    margin-right: 4%;
}
.mr-6{
    margin-right: 6%;
}