/* You can add global styles to this file, and also import other style files */
/* Add application styles & imports to this file! */
@import "@angular/cdk/overlay-prebuilt.css";
@import "../src/app/landing/scss/fonts.var";
/*COLORS BACKGROUND*/
@import "./assets/custom/bg-colors.scss";
/*Fuentes*/
@import "./assets/custom/fuentes.scss";
/* Grillas VISTAS &  Dashboard*/
@import "./assets/custom/grid.scss";
/*BreakPoint*/
@import "./assets/custom/breakpoints.scss";
/* Spaciados*/
@import "./assets/custom/spacing.scss";
/*Elevation*/
@import "./assets/custom/elevation.scss";
/*Tooltip*/
@import "./assets/custom/tooltip.scss";

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@font-face {
  font-family: map-get($fonts-family, "primary-black");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-Black.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-Black.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-Black.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-black-italic");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-BlackItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-BlackItalic.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-BlackItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-bold");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-Bold.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-Bold.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-Bold.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-bold-italic");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-BoldItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-BoldItalic.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-BoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-italic");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-Italic.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-Italic.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-Italic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-medium");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-Medium.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-Medium.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-Medium.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-medium-italic");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-MediumItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-MediumItalic.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-MediumItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-regular");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-Regular.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-Regular.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-Regular.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-semi-bold");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-SemiBold.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-SemiBold.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "primary-semi-bold-italic");
  src: url('./assets/landing/fonts/Red_hat_display/woff2/RedHatDisplay-SemiBoldItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Red_hat_display/woff/RedHatDisplay-SemiBoldItalic.woff') format('woff'),
    url('./assets/landing/fonts/Red_hat_display/ttf/RedHatDisplay-SemiBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-black");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-Black.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-Black.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-Black.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-black-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-BlackItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-BlackItalic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-BlackItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-bold");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-Bold.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-Bold.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-Bold.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-bold-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-BoldItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-BoldItalic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-BoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-extra-bold");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-ExtraBold.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-ExtraBold.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-ExtraBold.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-extra-bold-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-ExtraBoldItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-ExtraBoldItalic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-ExtraBoldItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-extra-light");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-ExtraLight.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-ExtraLight.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-ExtraLight.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-extra-light-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-ExtraLightItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-ExtraLightItalic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-ExtraLightItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-Italic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-Italic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-Italic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-light");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-Light.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-Light.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-Light.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-light-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-LightItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-LightItalic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-LightItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-medium");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-Medium.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-Medium.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-Medium.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-medium-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-MediumItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-MediumItalic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-MediumItalic.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-regular");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-Regular.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-Regular.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-Regular.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-semi-bold");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-SemiBold.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-SemiBold.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: map-get($fonts-family, "secondary-semi-bold-italic");
  src: url('./assets/landing/fonts/Nunito/woff2/Nunito-SemiBoldItalic.woff2') format('woff2'),
    url('./assets/landing/fonts/Nunito/woff/Nunito-SemiBoldItalic.woff') format('woff'),
    url('./assets/landing/fonts/Nunito/ttf/Nunito-SemiBoldItalic.ttf') format("truetype");
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: #ffffff00;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: none !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .55);
}

$color-default: rgba(0, 0, 0, 0.5);
$primary-color: #640AB0;


.overlay-backdrop {
  background-color: $color-default;
}

.btn-stroked-logged {
  background-color: #FF4D5E !important;
  outline: inherit;
  cursor: pointer;
  border: 1px solid;
  border-radius: 10px;
  padding: 0;
  height: 49px;
  color: #FFFFFF;
  border-color: #FF4D5E !important;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
    -webkit-appearance: none !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #c4c4c4;
    height: 2px;
  }
}

.cbx-input-error-wrapper {
  position: relative;
  padding-bottom: 1.34375em;
}

.cbx-input-error-message {
  position: absolute;
  width: 100%;
  color: #ff4d5e;
  font-size: 12px;
  line-height: 16px;
  font-family: "Nunito-Medium";
  margin-top: 4px;
}

.cbx-dashboard>* {
  box-sizing: border-box;
}

.subrayado {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.centrado {
  text-align: center !important;
}

.sinPadding {
  padding: 0 !important;
}

.grecaptcha-badge {
  display: none !important;
}

.dialog-cookies {
  margin-bottom: 0 !important;
  width: 100%;
  max-width: none !important;
  background-color: #efe7f7;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 32px 16px;
    box-shadow: 0px 4px 24px rgb(92 31 201 / 16%);
    border-radius: 16px;
    border-radius: unset;
    transition: none;
    background-color: #efe7f7;
  }
}

.dialog-cookies-config .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px;
  transition: none;
  padding: 2rem;
}

.mx-auto {
  margin: 0 auto;
}

.dialog-cookies-config .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #ff7784;
  --mdc-switch-selected-handle-color: #ff7784;
  --mdc-switch-selected-hover-state-layer-color: #ff7784;
  --mdc-switch-selected-pressed-state-layer-color: #ff7784;
  --mdc-switch-selected-focus-handle-color: #ff7784;
  --mdc-switch-selected-hover-handle-color: #ff7784;
  --mdc-switch-selected-pressed-handle-color: #ff7784;
  --mdc-switch-selected-focus-track-color: #ff7784;
  --mdc-switch-selected-hover-track-color: #ff7784;
  --mdc-switch-selected-pressed-track-color: #ff7784;
  --mdc-switch-selected-track-color: #ff7784;
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #9e9e9e;
  --mdc-switch-disabled-unselected-handle-color: #9e9e9e;
  --mdc-switch-disabled-selected-track-color: #9e9e9e;
  --mdc-switch-disabled-unselected-track-color: #9e9e9e;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #592222;
  --mdc-switch-unselected-hover-state-layer-color: #6f2828;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #9e9e9e;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}

.dialog-primary .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 40px;
  background: transparent;

  @include media-breakpoint-landing("lt-md") {
    border-radius: 20px;
  }

  & .mdc-dialog__title {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    background-color: #640ab0;

    @include media-breakpoint-landing("lt-md") {
      height: 53px;
    }

    & .close-btn {
      margin-right: 20px;
      color: #ffffff;

      @include media-breakpoint-landing("lt-md") {
        margin-right: 10px;
      }
    }
  }

  & .mdc-dialog__content {
    margin: 0;
    padding: 80px 65px 10px;
    background: #fff;
    text-align: justify;

    @include media-breakpoint-landing("lt-md") {
      padding: 39px 28px 10px;
      font-size: 12px !important;
      line-height: 18px !important;
    }

    &::-webkit-scrollbar {
      width: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d4adf4;
      border-radius: 20px;
    }

    .title {
      font-size: 25px;
      line-height: 24px;
      color: #640ab0;
      text-align: center;
      margin-bottom: 44px;
      font-family: map-get($fonts-family, "secondary-bold") !important;

      @include media-breakpoint-landing("lt-md") {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 38px;
      }
    }

    .subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #640ab0;
      font-family: map-get($fonts-family, "secondary-bold") !important;

      @include media-breakpoint-landing("lt-md") {
        font-size: 12px;
        line-height: 18px;
      }

      &__nivel2 {
        margin-left: 35px !important;
      }
    }

    .paragraph {
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 20px 0;
      color: #000000;
      font-family: map-get($fonts-family, "secondary-medium") !important;

      @include media-breakpoint-landing("lt-md") {
        font-size: 12px;
        line-height: 18px;
      }

      &__first {
        margin-bottom: 24px;
      }

      &__nivel2 {
        margin-left: 70px !important;
      }

      &__nivel3 {
        margin-left: 105px !important;
      }
    }

    .list {
      margin: 0 0 20px 35px;
      color: #000000;
      font-family: map-get($fonts-family, "secondary-medium") !important;

      &__circle {
        list-style-type: circle;
      }

      &__letters {
        list-style-type: lower-alpha;
      }

      &__item {
        font-family: map-get($fonts-family, "secondary-medium") !important;
        margin-bottom: 16px;
      }
    }

    .link {
      color: #640ab0;
      word-break: break-all;
    }

    .nivel2 {
      margin-left: 70px;
    }

    .table-modal {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 20px;

      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center !important;
      }

      th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #640ab0;
        color: white;
        font-family: map-get($fonts-family, "secondary-bold") !important;
      }

      td {
        font-family: map-get($fonts-family, "secondary-medium") !important;
      }
    }
  }
}

.underline-title {
  position: relative;
  font-family: 'Red Hat Display';
  color: #626262;
  font-size: 1.06rem;
  line-height: 1.375em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  &::before {
    content: "";
    position: absolute;
    width: 5%;
    height: 0.125rem;
    top: 1.25em;
    background-color: #FF4D5E;
    border-radius: 1rem;
  }
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #ff4d5e;
}

.litle-line {
  border-bottom: 1.5px solid #FF4D5E;
  width: 17px;
}

.v-line {
  border: 1px solid #000000;
  opacity: 15%;

  @include media-breakpoint-landing("lt-md") {
    display: none;
  }
}

.relative {
  position: relative;
}

.subline {
  position: relative;
  display: inline-block;
}

.subline::before {
  content: "";
  position: absolute;
  width: 2ch;
  height: 2px;
  background: #FF4D5E;
  border-radius: 2px;
  bottom: -3px;
  left: 0;
}
